import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { Link } from "react-router-dom";
import LoginLogo from "./Logo";

const LoginForm = () => {
  const { Login, errors, setErrors } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);

  const handleSubmit = () => {
    return (e) => {
      e.preventDefault();
      Login(email, password, remember);
    };
  };

  useEffect(() => {
    return () => {
      setErrors(null);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit()} className="auth-form">
      <div className="hidden flex-col xl:flex">
        <LoginLogo />
      </div>
      <div className="flex flex-col">
        <h1>Login</h1>
        <div className="input-container">
          <input
            className={email ? "filled" : null}
            type="email"
            name="text"
            autocomplete="off"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <label className="input-label">Email</label>
        </div>
        <div className="input-container">
          <input
            className={password ? "filled" : null}
            type="password"
            name="text"
            autocomplete="off"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <label className="input-label">Password</label>
        </div>
        {errors && <span className="text-red-500">{errors.message}</span>}
        <div className="mt-4 flex flex-row gap-2">
          <label className="checkbox-container h-6 w-6 cursor-pointer">
            <input
              type="checkbox"
              checked={remember}
              onChange={(e) => setRemember(e.target.checked)}
            />
            <svg viewBox="0 0 64 64" height="100%" width="100%">
              <path
                d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                pathLength="575.0541381835938"
                className="path"
              ></path>
            </svg>
          </label>
          <span
            className="cursor-pointer"
            onClick={() => setRemember(!remember)}
          >
            Remember Me
          </span>
        </div>
        <button>Submit</button>
        <span className="mt-2">
          Don't have an account? <Link to="/auth/register">Register</Link>
        </span>
      </div>
    </form>
  );
};

export default LoginForm;
