const SideNav = () => {
  const handleTest = async () => {
    await fetch("http://localhost:5000/api/v1/minecraft/test", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        player: "__Pumbaa__",
        commands: ["give %username% diamond_sword", "give %username% grass_block"],
      }),
    });
  };

  const handleCreateWebsite = async () => {
    await fetch("http://localhost:5000/api/v1/website/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        subdomain: "minecraft",
      }),
    });
  };
  return (
    <nav className="sidenavbar">
      <h1>Side</h1>
      <button onClick={() => handleTest()}>Test</button>
      <button onClick={() => handleCreateWebsite()}>Create Website</button>
    </nav>
  );
};

export default SideNav;
