const AuthDarkModeButton = (props) => {
  const { darkMode, setDarkMode } = props;
  return (
    <button
      className="absolute bottom-0 left-0 m-4 h-12 w-12 rounded-full bg-primary shadow-lg outline-0"
      onClick={() => setDarkMode(!darkMode)}
    >
      <i
        className={"fas text-dark-primary " + (darkMode ? "fa-moon" : "fa-sun")}
      ></i>
    </button>
  );
};

export default AuthDarkModeButton;
