import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import LoginLogo from "./Logo";

const RegisterForm = () => {
  const { Register, errors, setErrors } = useAuth();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [terms, setTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    return () => {
      setErrors(null);
    };
  }, []);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const handleSubmit = () => {
    return (e) => {
      e.preventDefault();
      Register(email, firstname, lastname, password, confirm);
    };
  };

  const handleEmail = (text) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!text) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: null,
      }));
      return;
    }
    if (!emailRegex.test(text)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address",
      }));
      return;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: null,
    }));
    return;
  };

  const handlePassword = (text) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!text) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: null,
      }));
      return;
    }
    if (!passwordRegex.test(text)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character",
      }));
      return;
    }
    if (confirm && text !== confirm) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: null,
        confirm: "Passwords do not match",
      }));
      return;
    } else if (confirm && text === confirm) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirm: null,
      }));
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: null,
    }));
    return;
  };

  const handleConfirm = (text) => {
    if (text !== password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirm: "Passwords do not match",
      }));
      return;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      confirm: null,
    }));
    return;
  };

  return (
    <form onSubmit={handleSubmit()} className="auth-form">
      <div className="hidden flex-col xl:flex">
        <LoginLogo />
      </div>

      <div className="flex flex-col">
        <h1>Register</h1>
        <div className="input-container">
          <input
            className={(email ? "filled" : "") + (errors?.email ? "error" : "")}
            type="email"
            name="text"
            autocomplete="off"
            onChange={(e) => {
              handleEmail(e.target.value);
              setEmail(e.target.value.toLowerCase());
            }}
            value={email}
          />
          <label className="input-label">Email</label>
        </div>
        {errors?.email ? (
          <span className="text-red-500">{errors.email}</span>
        ) : (
          <br />
        )}
        <div className="input-container">
          <input
            className={
              (firstname ? "filled" : "") + (errors?.firstname ? "error" : "")
            }
            type="firstname"
            name="text"
            autocomplete="off"
            onChange={(e) => setFirstname(e.target.value)}
            value={firstname}
          />
          <label className="input-label">First Name</label>
        </div>
        {errors?.firstname ? (
          <span className="text-red-500">{errors.firstname}</span>
        ) : (
          <br />
        )}
        <div className="input-container">
          <input
            className={
              (lastname ? "filled" : "") + (errors?.lastname ? "error" : "")
            }
            type="lastname"
            name="text"
            autocomplete="off"
            onChange={(e) => setLastname(e.target.value)}
            value={lastname}
          />
          <label className="input-label">Last Name</label>
        </div>
        {errors?.lastname ? (
          <span className="text-red-500">{errors.lastname}</span>
        ) : (
          <br />
        )}
        <div className="input-container">
          <input
            className={
              (password ? "filled" : "") + (errors?.password ? "error" : "")
            }
            type={showPassword ? "text" : "password"}
            name="text"
            autocomplete="off"
            onChange={(e) => {
              setPassword(e.target.value);
              handlePassword(e.target.value);
            }}
            value={password}
          />
          <label className="input-label">Password</label>
        </div>
        {errors?.password ? (
          <span className="text-red-500">{errors.password}</span>
        ) : (
          <br />
        )}
        <div className="input-container">
          <input
            className={
              (confirm ? "filled" : "") + (errors?.confirm ? "error" : "")
            }
            type={showPassword ? "text" : "password"}
            name="text"
            autocomplete="off"
            onChange={(e) => {
              setConfirm(e.target.value);
              handleConfirm(e.target.value);
            }}
            value={confirm}
          />
          <label className="input-label">Confirm Password</label>
        </div>
        {errors?.confirm ? (
          <span className="text-red-500">{errors.confirm}</span>
        ) : (
          <br />
        )}
        <div className="mt-4 flex flex-row gap-2">
          <label className="checkbox-container h-6 w-6 cursor-pointer">
            <input
              type="checkbox"
              checked={terms}
              onChange={(e) => setTerms(e.target.checked)}
            />
            <svg viewBox="0 0 64 64" height="100%" width="100%">
              <path
                d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                pathLength="575.0541381835938"
                className="path"
              ></path>
            </svg>
          </label>
          <span className="cursor-pointer" onClick={() => setTerms(!terms)}>
            Agree to <a href="/legal/tos">terms of service</a>
          </span>
        </div>
        {errors?.message && (
          <span className="mt-4 text-red-500">{errors.message}</span>
        )}
        <button
          disabled={
            !firstname ||
            !lastname ||
            !email ||
            !password ||
            !confirm ||
            !terms ||
            errors?.email ||
            errors?.firstname ||
            errors?.lastname ||
            errors?.password ||
            errors?.confirm
              ? true
              : null
          }
        >
          Submit
        </button>
        <span className="mt-2">
          Already have an account? <Link to="/auth/login">Login</Link>
        </span>
      </div>
    </form>
  );
};

export default RegisterForm;
