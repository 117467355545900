import { useAuth } from "../../../auth/hooks/useAuth";

const NavBarProfileDropdown = () => {
  const { Logout } = useAuth();
  return (
    <div
      className="dropdown-menu dropdown-menu-right"
      aria-labelledby="navbarDropdown"
    >
      <a className="dropdown-item" href="/profile">
        <i className="fas fa-user-circle"></i> Profile
      </a>
      <a className="dropdown-item" href="/settings">
        <i className="fas fa-cog"></i> Settings
      </a>
      <div className="dropdown-divider"></div>
      <button className="dropdown-item" onClick={() => Logout()}>
        <i className="fas fa-sign-out-alt"></i> Logout
      </button>
    </div>
  );
};

export default NavBarProfileDropdown;
