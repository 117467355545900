import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  //Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import LoadingOverlay from "./modules/core/components/LoadingOverlay";
import NavBar from "./modules/core/components/navbar/NavBar";
import HomePage from "./modules/core/pages/Home";
import { useAuth } from "./modules/auth/hooks/useAuth";
import LoginForm from "./modules/auth/components/LoginForm";
import RegisterForm from "./modules/auth/components/RegisterForm";
import { useEffect, useState } from "react";
import AuthDarkModeButton from "./modules/auth/components/DarkModeButton";
import SideNav from "./modules/core/components/sidenavbar/SideNav";
//import socketIO from "socket.io-client";

const helmetContext = {};

function App() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("darkMode")) {
      localStorage.setItem("darkMode", true);
      setDarkMode(true);
    } else {
      const isDarkMode = localStorage.getItem("darkMode") === "true";
      setDarkMode(isDarkMode);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const { authenticated, loading } = useAuth();

  return (
    <div className="App">
      <HelmetProvider context={helmetContext}>
        <Router>
          <Toaster
            toastOptions={{
              duration: 4000,
              position: "bottom-right",
              error: {
                style: {
                  border: "#444 1px solid",
                  background: "#212121",
                  color: "#fff",
                },
              },
            }}
          />
          {authenticated ? (
            <div className="flex h-screen w-screen flex-row overflow-hidden">
              <div className="hidden h-full lg:flex lg:min-w-[290px]">
                <SideNav />
              </div>
              <div className="w-full">
                <NavBar />
                <div className="overflow-y-auto">
                  <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route exact path="/*" element={<Navigate to="/" />} />
                  </Routes>
                </div>
              </div>
            </div>
          ) : (
            <>
              {!loading && (
                <div className="flex h-screen w-screen flex-col items-center justify-center overflow-hidden">
                  <Routes>
                    <Route exact path="/auth/login" element={<LoginForm />} />
                    <Route
                      exact
                      path="/auth/register"
                      element={<RegisterForm />}
                    />
                    <Route
                      exact
                      path="/*"
                      element={<Navigate to={"/auth/login"} />}
                    />
                  </Routes>
                  <AuthDarkModeButton
                    darkMode={darkMode}
                    setDarkMode={setDarkMode}
                  />
                </div>
              )}
            </>
          )}
          <Routes>
            <Route
              exact
              path="/*"
              element={<LoadingOverlay loading={loading} />}
            />
          </Routes>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
