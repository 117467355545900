import React, { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const controller = new AbortController();
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const CheckAuth = () => {
      setLoading(true);
      let count = 0;
      const fetchData = async () => {
        try {
          const response = await fetch("/api/v1/auth/check", {
            signal: controller.signal,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });

          const data = await response.json();

          console.log(data);

          if (data.authenticated) {
            setAuthenticated(true);
            setLoading(false);
            setUser(data.user);
          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
          if (count < 3) {
            setTimeout(() => {
              count++;
              fetchData();
            }, 2000);
          } else {
            count = 0;
            fetchData();
            toast.error("Unable to connect to authentication server");
            setErrors("Unable to connect to authentication server");
          }
        }
      };
      fetchData();
    };
    CheckAuth();

    return () => {
      controller.abort();
    };
  }, []);

  const Register = async (email, firstname, lastname, password, confirm) => {
    const response = await fetch("/api/v1/auth/register", {
      signal: controller.signal,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, firstname, lastname, password, confirm }),
    });

    const data = await response.json();

    if (data.error) {
      if (data.error.message) {
        toast.error(data.error.message);
      }
      setErrors(data.error);
    } else {
      setAuthenticated(true);
      setUser(data.user);
    }
  };

  const Login = async (email, password, remember) => {
    setErrors(null);
    if (!email || !password) {
      setErrors({ message: "Please fill in all fields" });
      return;
    }
    const response = await fetch("/api/v1/auth/login", {
      signal: controller.signal,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, remember }),
    });

    const data = await response.json();

    if (data.error) {
      setErrors(data.error);
    } else {
      setAuthenticated(true);
      setUser(data.user);
    }
  };

  const Logout = async () => {
    setErrors(null);
    const response = await fetch("/api/v1/auth/logout", {
      signal: controller.signal,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (data.error) {
      setErrors(data.error);
    } else {
      setAuthenticated(false);
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        loading,
        errors,
        setErrors,
        user,
        Register,
        Login,
        Logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
