import { useEffect } from "react";

const LoadingOverlay = (props) => {
    const { loading } = props;

    useEffect(() => {
        const loadingElement = document.getElementsByClassName("loading")[0]; // Access the first element of the array
        if (loading && loadingElement) {
            loadingElement.style.display = "flex";
            loadingElement.style.opacity = "0";
            setTimeout(() => {
                loadingElement.style.opacity = "1";
            }, 1);
        } else if (!loading && loadingElement) {
            loadingElement.style.opacity = "0";
            setTimeout(() => {
                loadingElement.style.display = "none";
            }, 500);
        }
    }, [loading]);

    return (
        <div className="loading fixed left-0 top-0 z-999999 flex h-screen w-screen items-center justify-center bg-primary dark:bg-dark-primary transition-opacity duration-500">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-accent border-t-transparent"></div>
        </div>
    );
};

export default LoadingOverlay;
